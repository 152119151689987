<template>
    <div>
        <activator
            v-if="activeOrderTab == -1"
            @search="search"
            placeholder="ابحث عن طلب محدد"
        ></activator>
        <activator
            v-if="activeOrderTab == 0"
            @search="search"
            placeholder="ابحث عن إشعار محدد"
            btnText="تراجع"
            @ok="setTodayOrdersTabActive"
            noIcon
        ></activator>
        <activator
            v-if="activeOrderTab == 1"
            @search="search"
            placeholder="ابحث عن إعلان محدد"
            btnText="تراجع"
            @ok="setTodayOrdersTabActive"
            noIcon
        ></activator>
        <activator
            v-if="activeOrderTab == 2"
            @search="search"
            placeholder="ابحث عن وظيفة محددة"
            btnText="تراجع"
            @ok="setTodayOrdersTabActive"
            noIcon
        ></activator>
    </div>
</template>

<script>
import Activator from "@/EK-core/components/EK-dialog/activaitor.vue";
import { nullGuid } from "@Ekcore/util/global.js";
import { mapMutations, mapState } from "vuex";

export default {
    computed: {
        ...mapState({
            activeOrderTab: (state) => state.orders.activeOrderTab,
        }),
    },
    components: {
        Activator,
    },
    data: () => ({
        nullGuid,
    }),
    methods: {
        ...mapMutations([
            "Reset_Customer_Order_Dto",
            "Reset_Order_Cart_List_Dto",
            "Update_Orders_Active_Tab"
        ]),
        setTodayOrdersTabActive() {
            this.Update_Orders_Active_Tab(-1);
        },
        search(query) {
            this.$store.commit("Set_Search_Dto", {
                keys: [
                    "sendBy",
                    "senderName",
                    "createdDate",
                    "requestDate",
                    "activityName",
                    "dateCreated",
                    "responseDate",
                    "name",
                    "accountName",
                    "commerialActivityName",
                    "commertialActivityName",
                    "comertaialAcitvityName",
                    "comertialAcitvityName",
                    "cityName",
                    "titel",
                    "content",
                ],
                query,
            });
        },
    },
};
</script>

<style></style>
